function TermsOfUse() {
  return (
    <div className="max-w-6xl mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">Conditions Générales d'Utilisation et de vente</h1>

      <section className="space-y-6">
        <div>
          <h2 className="text-2xl font-semibold mb-4">1. Acceptation des Conditions</h2>
          <p className="mb-4 text-justify">
            En utilisant l'application Season: Listes & Recettes, vous acceptez d'être lié par les présentes Conditions Générales d'Utilisation. Si vous n'acceptez pas ces conditions, veuillez ne pas utiliser l'application.
          </p>
        </div>

        <div>
          <h2 className="text-2xl font-semibold mb-4">2. Description de l'Application</h2>
          <p className="mb-4 text-justify">
            Season: Listes & Recettes est une application de gestion de recettes et de planification de repas qui offre les fonctionnalités suivantes :
          </p>
          <ul className="list-disc ml-6 mb-4">
            <li>Création et gestion de recettes personnalisées</li>
            <li>Importation de recettes depuis des sites web</li>
            <li>Planification de repas hebdomadaire</li>
            <li>Génération automatique de listes de courses</li>
            <li>Fonctionnement hors ligne</li>
            <li>Stockage local des données sur votre appareil</li>
          </ul>
          <p className="mb-4 text-justify">
            L'application est accessible gratuitement avec des fonctionnalités de base. Une version premium est disponible pour accéder à des fonctionnalités avancées.
          </p>
        </div>

        <div>
          <h2 className="text-2xl font-semibold mb-4">3. Propriété Intellectuelle</h2>
          <p className="mb-4 text-justify">
            Tous les éléments de l'application (textes, images, logos, base de données, etc.) sont protégés par le droit d'auteur et sont la propriété exclusive de Season: Listes & Recettes. L'utilisateur s'engage à :
          </p>
          <ul className="list-disc ml-6 mb-4">
            <li>Ne pas copier ou reproduire le contenu de l'application sans autorisation</li>
            <li>Ne pas utiliser les images de la base de données à des fins commerciales</li>
            <li>Respecter les droits d'auteur des recettes importées depuis d'autres sites</li>
            <li>Ne pas modifier ou altérer les mentions de copyright et autres mentions de propriété</li>
          </ul>
        </div>

        <div>
          <h2 className="text-2xl font-semibold mb-4">4. Services Proposés</h2>
          <h3 className="text-xl font-semibold mb-2">Version Gratuite :</h3>
          <ul className="list-disc ml-6 mb-4">
            <li>Création et importation limité à 75 recettes</li>
            <li>Importation de recettes</li>
            <li>10 listes de courses simultanées maximum</li>
            <li>10 collections de recettes</li>
            <li>Publicités modérées</li>
          </ul>

          <h3 className="text-xl font-semibold mb-2">Version Premium (9.99€ TTC) :</h3>
          <ul className="list-disc ml-6 mb-4">
            <li>Nombre illimité de recettes personnalisées et de collections</li>
            <li>Listes de courses illimitées</li>
            <li>Un service sans bannière publicitaire et publicité interstitielle</li>
            <li>Sauvegarde automatique sur Google Drive</li>
            <li>Support prioritaire</li>
          </ul>
        </div>

        <div>
          <h2 className="text-2xl font-semibold mb-4">5. Protection des Données</h2>
          <p className="mb-4 text-justify">
            Nous accordons une grande importance à la protection de vos données personnelles. Notre politique de confidentialité détaille :
          </p>
          <ul className="list-disc ml-6 mb-4">
            <li>Les données collectées et leur utilisation</li>
            <li>Le stockage local des données sur votre appareil</li>
            <li>La sécurisation des données personnelles</li>
            <li>Vos droits concernant vos données</li>
          </ul>
        </div>

        <div>
          <h2 className="text-2xl font-semibold mb-4">6. Responsabilités</h2>
          <p className="mb-4 text-justify">
            L'utilisateur est responsable :
          </p>
          <ul className="list-disc ml-6 mb-4">
            <li>De l'exactitude des informations fournies</li>
            <li>Du contenu qu'il partage via l'application</li>
            <li>Du respect des droits des tiers</li>
            <li>Du respect des conditions d'utilisation des sites sources lors de l'importation de recettes</li>
            <li>Du contenu qu'il importe ou ajoute dans l'application</li>
          </ul>
          <p className="mb-4 text-justify">
            Season: Listes & Recettes ne peut être tenu responsable :
          </p>
          <ul className="list-disc ml-6 mb-4">
            <li>Des interruptions temporaires de service pour maintenance</li>
            <li>Des problèmes techniques indépendants de notre volonté</li>
            <li>De la perte de données due à une défaillance de l'appareil de l'utilisateur</li>
          </ul>
        </div>

        <div>
          <h2 className="text-2xl font-semibold mb-4">7. Paiement et Facturation</h2>
          <p className="mb-4 text-justify">
            Le paiement de la version premium s'effectue en une seule fois (one-time payment) et donne accès à vie aux fonctionnalités premium. Détails :
          </p>
          <ul className="list-disc ml-6 mb-4">
            <li>Prix unique : 9.99 € TTC</li>
            <li>Paiement sécurisé via le Playstore</li>
            <li>Pas d'abonnement récurrent</li>
            <li>Accès immédiat aux fonctionnalités premium après paiement</li>
          </ul>
        </div>

        <div>
          <h2 className="text-2xl font-semibold mb-4">8. Droit de Rétractation</h2>
          <p className="mb-4 text-justify">
            Conformément à la législation européenne, vous disposez d'un droit de rétractation de 14 jours à compter de votre achat. Pour exercer ce droit :
          </p>
          <ul className="list-disc ml-6 mb-4">
            <li>Contactez-nous à contact@season-app.com</li>
            <li>Indiquez clairement votre intention de vous rétracter</li>
            <li>Fournissez votre numéro de transaction ( Dans les paramètres Google Play Store - Paramètres - Paiement et abonnements - Historique des achats) et email</li>
          </ul>
          <p className="mb-4 text-justify">
            Le remboursement sera effectué dans un délai maximum de 14 jours, en utilisant le même moyen de paiement que celui utilisé pour l'achat.
          </p>
        </div>

        <div>
          <h2 className="text-2xl font-semibold mb-4">9. Modifications des CGU</h2>
          <p className="mb-4 text-justify">
            Nous nous réservons le droit de modifier ces CGU à tout moment. Les modifications entrent en vigueur dès leur publication. Pour les changements majeurs, nous vous informerons via :
          </p>
          <ul className="list-disc ml-6 mb-4">
            <li>Une notification dans l'application</li>
            <li>Une annonce sur notre site web</li>
          </ul>
          <p className="mb-4 text-justify">
            La continuation de l'utilisation de l'application après modification des CGU vaut acceptation des nouvelles conditions.
          </p>
        </div>

        <div>
          <h2 className="text-2xl font-semibold mb-4">10. Contact</h2>
          <p className="mb-4 text-justify">
            Pour toute question concernant ces CGU ou l'utilisation de l'application, vous pouvez nous contacter :
          </p>
          <ul className="list-disc ml-6 mb-4">
            <li>Par email : contact@season-app.com</li>
          </ul>
        </div>

        <div>
          <p className="mt-8 text-sm text-gray-600">
            Dernière mise à jour : 15/01/2025
          </p>
        </div>
      </section>
    </div>
  );
}

export default TermsOfUse;