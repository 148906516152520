import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import mainSplashImg from '../assets/main-splash.jpg';
import layerImg from '../assets/layer.png';

function ClosedBeta() {
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const [expandedFaq, setExpandedFaq] = useState<string | null>(null);
    const [countdown, setCountdown] = useState<{
      days: number;
      hours: number;
      minutes: number;
      seconds: number;
    }>({ days: 0, hours: 0, minutes: 0, seconds: 0 });

    useEffect(() => {
      const targetDate = new Date('2025-02-14T23:59:59');

      const updateCountdown = () => {
        const now = new Date();
        const difference = targetDate.getTime() - now.getTime();

        if (difference <= 0) {
          setCountdown({ days: 0, hours: 0, minutes: 0, seconds: 0 });
          return;
        }

        setCountdown({
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
          minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
          seconds: Math.floor((difference % (1000 * 60)) / 1000)
        });
      };

      const timer = setInterval(updateCountdown, 1000);
      updateCountdown();

      return () => clearInterval(timer);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
      <>
        <div className="max-w-5xl mx-auto px-3 md:px-8 md:py-12 py-6 space-y-8">
          {/* Application Overview Section */}
          <section className="space-y-8">
            <header className="space-y-6">
              <h1 className="md:text-3xl text-2xl font-bold text-white">
                Invitation au <em>test fermé</em> de{" "}
                <span className="text-[#7CA834]">
                  Season: Listes & Recettes
                </span>
              </h1>
              <div className="flex items-center gap-4 text-gray-400 text-sm">
                <div className="flex items-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                    />
                  </svg>
                  <span>Kiato</span>
                </div>
                <div className="flex items-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
                    />
                  </svg>
                  <time>09 Janvier 2025</time>
                </div>
              </div>
              <div className="border-b border-gray-700"></div>
              <h2 className="text-2xl font-medium text-white">
                À propos de l'application
              </h2>
            </header>

            <div className="flex flex-col md:flex-row-reverse items-start gap-8 md:gap-12">
              <div className="w-full md:w-1/2">
                <div className="relative aspect-[16/9] rounded-xl overflow-hidden shadow-2xl">
                  <img
                    src={mainSplashImg}
                    alt="Season App Presentation"
                    className="w-full h-full object-cover cursor-pointer hover:scale-105 transition-transform duration-300"
                  />
                </div>
              </div>
              <div className="w-full md:w-1/2 prose prose-invert">
                <p className=" text-gray-100 leading-relaxed">
                  Vous êtes invités à participer au <em>test fermé</em> de{" "}
                  <span className="text-[#7CA834]">
                    Season: Listes & Recettes
                  </span>
                  , une application gratuite et intuitive qui vous aide à gérer{" "}
                  <strong>vos repas au quotidien</strong>. Créez ou{" "}
                  <strong>importez facilement des recettes de saison </strong>
                  depuis le web et ajoutez{" "}
                  <strong>les ingrédients en un clic</strong> dans vos listes de
                  courses.
                  <br />
                  <Link 
                    to="/" 
                    className="text-[#7CA834] hover:underline"
                    onClick={() => {
                      setTimeout(() => {
                        const element = document.getElementById('images');
                        element?.scrollIntoView({ behavior: 'smooth' });
                      }, 100);
                    }}
                  >
                    <u>Découvrez Season en images</u>
                  </Link>
                  <br />
                  <br />
                  Dans ce test fermé, vous serez amené à tester les
                  fonctionnalités de l'application et à transmettre, si vous le
                  souhaitez, vos retours sur l'application.
                </p>
              </div>
            </div>
          </section>

          {/* How to Participate Section */}
          <section className="space-y-4 ">
            <div className="bg-neutral-800 p-4 md:p-8 rounded-lg border border-gray-500">
              <div className="space-y-4">
                <h3 className="text-xl font-semibold text-gray-50 pt-3 flex items-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="#8FC23B"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                    />
                  </svg>
                  À propos du test fermé
                </h3>
                <div className="text-gray-50 mb-8">
                  <p>
                    Le test fermé est une étape clé pour publier une application
                    sur le Google Play Store. Si ce projet vous inspire, je vous
                    invite à rejoindre l'aventure et à y contribuer activement !
                    <br />
                    <br />
                    L'objectif est d'évaluer l'application par un nombre défini
                    de testeurs<strong> pendant 15 jours</strong> afin de
                    l'améliorer en fonction de vos retours.
                    <br />
                  </p>
                  <p className="mt-4 p-4 bg-[#e2dfd6] border-x-4 border-[#8FC23B] rounded-lg text-neutral-800 font-['Ubuntu']">
                    <strong>Important </strong>: Pour que votre participation
                    soit valide, merci de{" "}
                    <strong>ne pas désinstaller l'application </strong>
                    durant les 15 jours qui suivent son installation.
                  </p>

                  {/* FAQ Item 6 - Votre rôle */}
                  <div className="border border-gray-700 rounded-lg overflow-hidden mt-6">
                    <button
                      className="w-full px-4 py-3 text-left bg-neutral-800 hover:bg-neutral-700 transition-colors duration-200 flex justify-between items-center"
                      onClick={() => {
                        const currentExpanded = expandedFaq;
                        setExpandedFaq(
                          currentExpanded === "your-role" ? null : "your-role"
                        );
                      }}
                    >
                      <span className=" font-semibold text-gray-50">
                        Quel sera votre rôle en tant que testeur ?
                      </span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className={`w-6 h-6 transform transition-transform duration-200 ${
                          expandedFaq === "your-role" ? "rotate-180" : ""
                        }`}
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="#8FC23B"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M19 9l-7 7-7-7"
                        />
                      </svg>
                    </button>
                    {expandedFaq === "your-role" && (
                      <div className="px-4 py-3 bg-neutral-800">
                        <ul className="list-disc pl-6 space-y-2 text-gray-50">
                          <li>
                            Utiliser régulièrement l'application durant cette
                            phase de test
                          </li>
                          <li>Tester la plupart des fonctionnalités</li>
                          <li>
                            Envoyer vos retours au développeur (via le menu
                            "Nous contacter" dans l'application ou en envoyant
                            un mail directement à l'adresse
                            contact@season-app.com)
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>

                  {/* FAQ Item 7 - Premium offert */}
                  <div className="border border-gray-700 rounded-lg overflow-hidden mt-6">
                    <button
                      className="w-full px-4 py-3 text-left bg-neutral-800 hover:bg-neutral-700 transition-colors duration-200 flex justify-between items-center"
                      onClick={() => {
                        const currentExpanded = expandedFaq;
                        setExpandedFaq(
                          currentExpanded === "premium-test"
                            ? null
                            : "premium-test"
                        );
                      }}
                    >
                      <span className=" font-semibold text-gray-50">
                        Version Premium
                      </span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className={`w-6 h-6 transform transition-transform duration-200 ${
                          expandedFaq === "premium-test" ? "rotate-180" : ""
                        }`}
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="#8FC23B"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M19 9l-7 7-7-7"
                        />
                      </svg>
                    </button>
                    {expandedFaq === "premium-test" && (
                      <div className="px-4 py-3 bg-neutral-800">
                        <p className="text-gray-50">
                          Afin de tester l'application dans son ensemble, toutes
                          les fonctionnalités Premium seront débloquées pour
                          tous les testeurs enregistrés{" "}
                          <u>durant la phase de test fermé</u>. <br />
                          Ceci est optionnel, vous pouvez tout à fait tester
                          l'application sans la version Premium.
                          <br /> <br />
                          <strong>Seuls les comptes associés</strong> au test
                          fermé pourrons profiter de cette offre.
                          <br />
                          <br />
                          Pour en profiter:
                        </p>
                        <ul className="list-disc pl-6 space-y-2 text-gray-50 mt-3">
                          <li>
                            Installer l'application avec le compte associé au
                            test fermé
                          </li>
                          <li>
                            Se rendre dans le menu Premium de l'application
                          </li>
                          <li>Appuyer sur le bouton d'achat Premium</li>
                          <li>
                            En bas de la fenêtre du paiement, si votre compte
                            bien associé au test fermé, vous verrez "Carte test,
                            toujours approuvée
                            <span className="inline-flex items-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                className="w-4 h-4 ml-1 text-[#8FC23B] border-2 border-[#8FC23B] rounded-full"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M5 13l4 4L19 7"
                                />
                              </svg>
                              "
                            </span>
                          </li>
                        </ul>
                        <p className="text-gray-50 mt-3">
                          Vous effecturez alors un{" "}
                          <strong>paiement test</strong> qui ne vous sera pas
                          facturé, vous pourrez alors profiter de tous les
                          avantage Premium!
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="bg-neutral-800 md:p-8 p-4 rounded-lg border-2 border-[#7CA834] shadow-lg relative overflow-hidden">
            {/* Remplacer les éléments de design existants par la nouvelle image */}
            <div className="absolute top-0 right-0 opacity-95">
              <img
                src={layerImg}
                alt="Layer decoration"
                className="md:w-16 w-12"
              />
            </div>
            {/* Supprimer l'ancien élément de design
            <div className="absolute top-0 right-0 w-32 h-32 transform translate-x-16 -translate-y-16">
              <div className="w-full h-full bg-[#8FC23B]/10 rounded-full"></div>
            </div>
            */}
            <div className="absolute bottom-0 left-0 w-24 h-24 transform -translate-x-12 translate-y-12">
              <div className="w-full h-full  md:bg-[#8FC23B]/5 rounded-full"></div>
            </div>

            <div className="space-y-6 relative z-10">
              <div className="space-y-4">
                <h3 className="text-2xl font-semibold text-white flex items-center gap-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="#8FC23B"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                  Rejoindre le test fermé
                </h3>
                <div className="flex flex-col md:flex-row gap-6 items-start">
                  <div className="flex-1 space-y-4">
                    <p className="text-gray-50 ">
                      Entrez votre adresse email Google Play Store pour
                      participer au test fermé. Les instructions vous seront envoyées à cette adresse sous 24h.
                      <span className="block mt-2 text-sm text-gray-400 italic">
                        Votre adresse ne sera pas partagée ou utilisée à des
                        fins commerciales.
                      </span>
                    </p>
                    <div className="bg-[#e2dfd6] p-4 rounded-lg border-x-4 border-[#8FC23B] text-neutral-800 font-normal font-['Ubuntu']">
                      <strong>Important :</strong> Veuillez utiliser l'adresse{" "}
                      <strong>email associée</strong> à votre compte Google Play
                      Store.
                    </div>
                  </div>

                  <form
                    name="beta-registration"
                    method="POST"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    className="w-full md:w-96 md:bg-neutral-900/50 md:p-6 md:rounded-xl"
                    onSubmit={(e) => {
                      e.preventDefault();
                      const form = e.target as HTMLFormElement;
                      const formData = new FormData(form);
                      fetch("/", {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/x-www-form-urlencoded",
                        },
                        body: new URLSearchParams(formData as any).toString(),
                      })
                        .then(() => {
                          alert(
                            "Merci ! Votre inscription a bien été prise en compte."
                          );
                          form.reset();
                        })
                        .catch((error) => {
                          console.error("Error:", error);
                          alert(
                            "Une erreur s'est produite. Veuillez réessayer."
                          );
                        });
                    }}
                  >
                    <input
                      type="hidden"
                      name="form-name"
                      value="beta-registration"
                    />
                    <input type="hidden" name="bot-field" />

                    <div className="space-y-4">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        required
                        className="w-full px-4 py-3 bg-neutral-800 border border-gray-600 rounded-lg text-gray-50 focus:outline-none focus:border-[#8FC23B] focus:ring-1 focus:ring-[#8FC23B]"
                        placeholder="votre@email.com"
                      />
                      <button
                        type="submit"
                        className="w-full bg-[#8FC23B] text-white font-semibold py-3 px-6 rounded-lg hover:bg-[#7CA834] transition-colors duration-200 flex items-center justify-center gap-2 group"
                      >
                        Rejoindre le test
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={2}
                          stroke="currentColor"
                          className="w-5 h-5 transform transition-transform group-hover:translate-x-1"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                          />
                        </svg>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="space-y-4 mt-8 border-t border-gray-700 pt-8">
                <div className="text-center">
                  <p className="text-gray-400 mb-2">Le test fermé débutera dans</p>
                  <div className="flex justify-center gap-4">
                    {countdown.days <= 0 && countdown.hours <= 0 && countdown.minutes <= 0 && countdown.seconds <= 0 ? (
                      <p className="text-[#8FC23B] text-xl font-bold">Le test fermé est terminé</p>
                    ) : (
                      <>
                        <div className="text-center">
                          <div className="text-3xl font-bold text-[#8FC23B]">{countdown.days}</div>
                          <div className="text-sm text-gray-400">jours</div>
                        </div>
                        <div className="text-center">
                          <div className="text-3xl font-bold text-[#8FC23B]">{countdown.hours}</div>
                          <div className="text-sm text-gray-400">heures</div>
                        </div>
                        <div className="text-center">
                          <div className="text-3xl font-bold text-[#8FC23B]">{countdown.minutes}</div>
                          <div className="text-sm text-gray-400">minutes</div>
                        </div>
                        <div className="text-center">
                          <div className="text-3xl font-bold text-[#8FC23B]">{countdown.seconds}</div>
                          <div className="text-sm text-gray-400">secondes</div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* FAQ Section */}
          <section className="space-y-6">
            <h2 className="text-2xl font-semibold text-white">F.A.Q</h2>
            <div className="space-y-4">
              {/* FAQ Item 1 */}
              <div className="border border-gray-700 rounded-lg overflow-hidden">
                <button
                  className="w-full px-4 py-3 text-left bg-neutral-800 hover:bg-neutral-700 transition-colors duration-200 flex justify-between items-center"
                  onClick={() => {
                    const currentExpanded = expandedFaq;
                    setExpandedFaq(
                      currentExpanded === "what-is-closed-beta"
                        ? null
                        : "what-is-closed-beta"
                    );
                  }}
                >
                  <span className="text-lg font-semibold text-gray-50">
                    Qu'est-ce qu'un test fermé ?
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={`w-6 h-6 transform transition-transform duration-200 ${
                      expandedFaq === "what-is-closed-beta" ? "rotate-180" : ""
                    }`}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="#8FC23B"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>
                {expandedFaq === "what-is-closed-beta" && (
                  <div className="px-4 py-3 bg-neutral-800">
                    <p className="text-gray-50 mb-4">
                      Un <em>test fermé</em> est une phase de test exclusive où
                      un nombre limité d'utilisateurs sont invités à tester
                      l'application avant son lancement officiel. Cette approche
                      permet d'avoir des <strong>retours précieux</strong> pour
                      améliorer l'application et offrir la meilleure expérience
                      possible.
                    </p>
                    <h3 className="text-lg font-semibold text-gray-50 mb-3">
                      Les objectifs
                    </h3>
                    <ul className="list-disc list-inside space-y-2 text-gray-50">
                      <li>Adapter les fonctionnalités selon vos retours</li>
                      <li>Corriger les bugs</li>
                      <li>Améliorer l'utilisation</li>
                    </ul>
                  </div>
                )}
              </div>

              {/* FAQ Item 2 */}
              <div className="border border-gray-700 rounded-lg overflow-hidden">
                <button
                  className="w-full px-4 py-3 text-left bg-neutral-800 hover:bg-neutral-700 transition-colors duration-200 flex justify-between items-center"
                  onClick={() => {
                    const currentExpanded = expandedFaq;
                    setExpandedFaq(
                      currentExpanded === "features-to-test"
                        ? null
                        : "features-to-test"
                    );
                  }}
                >
                  <span className="text-lg font-semibold text-gray-50">
                    Quels sont les fonctionnalités à tester ?
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={`w-6 h-6 transform transition-transform duration-200 ${
                      expandedFaq === "features-to-test" ? "rotate-180" : ""
                    }`}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="#8FC23B"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>
                {expandedFaq === "features-to-test" && (
                  <div className="px-4 py-3 bg-neutral-800">
                    <p className="text-gray-50 mb-4">
                      Aucune consigne n'est définie, utilisez l'application
                      comme vous le souhaitez. Toutefois, certaines
                      fonctionnalités demandent une attention particulière pour
                      améliorer l'experience utilisateur. Notamment :
                    </p>
                    <ul className="list-disc list-inside space-y-2 text-gray-50">
                      <li>L'ajout des ingrédients dans la liste de courses</li>
                      <li>La création et la gestion des listes de courses</li>
                      <li>L'import et export des recettes</li>
                      <li>L'importation de recettes depuis des sites web</li>
                    </ul>
                  </div>
                )}
              </div>

              {/* FAQ Item 3 */}
              <div className="border border-gray-700 rounded-lg overflow-hidden">
                <button
                  className="w-full px-4 py-3 text-left bg-neutral-800 hover:bg-neutral-700 transition-colors duration-200 flex justify-between items-center"
                  onClick={() => {
                    const currentExpanded = expandedFaq;
                    setExpandedFaq(
                      currentExpanded === "report-bug" ? null : "report-bug"
                    );
                  }}
                >
                  <span className="text-lg font-semibold text-gray-50">
                    Comment signaler un bug ?
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={`w-6 h-6 transform transition-transform duration-200 ${
                      expandedFaq === "report-bug" ? "rotate-180" : ""
                    }`}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="#8FC23B"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>
                {expandedFaq === "report-bug" && (
                  <div className="px-4 py-3 bg-neutral-800">
                    <p className="text-gray-50">
                      Pour faire part de vos retours, vous pouvez utiliser la
                      fonctionnalité "Nous contacter" dans l'application ou
                      envoyer un email à contact@season-app.com.
                    </p>
                  </div>
                )}
              </div>

              {/* FAQ Item 4 */}
              <div className="border border-gray-700 rounded-lg overflow-hidden">
                <button
                  className="w-full px-4 py-3 text-left bg-neutral-800 hover:bg-neutral-700 transition-colors duration-200 flex justify-between items-center"
                  onClick={() => {
                    const currentExpanded = expandedFaq;
                    setExpandedFaq(
                      currentExpanded === "is-free" ? null : "is-free"
                    );
                  }}
                >
                  <span className="text-lg font-semibold text-gray-50">
                    L'application est-elle gratuite ?
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={`w-6 h-6 transform transition-transform duration-200 ${
                      expandedFaq === "is-free" ? "rotate-180" : ""
                    }`}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="#8FC23B"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>
                {expandedFaq === "is-free" && (
                  <div className="px-4 py-3 bg-neutral-800">
                    <p className="text-gray-50 mb-4">
                      L'application utilise le modèle économique{" "}
                      <strong>"Freemium"</strong>, elle peut être utilisée
                      gratuitement mais contient des publicités et certaines
                      limitations comme le nombre de recettes importées. Une{" "}
                      <em>version premium</em> est disponible pour supprimer les
                      annonces et les limitations.
                    </p>
                    <h3 className="text-lg font-semibold text-gray-50 mb-3">
                      Les 3 types d'annonces
                    </h3>
                    <ul className="list-disc list-inside space-y-2 text-gray-50">
                      <li>Un bannière située en haut de l'application</li>
                      <li>
                        Une annonce interstitielle qui s'affiche lors d'une
                        importation de recette ( limité à 1 toutes les 15
                        minutes)
                      </li>
                      <li>
                        Une annonce adaptative affichée sur la page
                        d'importation de recette
                      </li>
                    </ul>
                  </div>
                )}
              </div>

              {/* FAQ Item 5 - Premium benefits */}
              <div className="border border-gray-700 rounded-lg overflow-hidden">
                <button
                  className="w-full px-4 py-3 text-left bg-neutral-800 hover:bg-neutral-700 transition-colors duration-200 flex justify-between items-center"
                  onClick={() => {
                    const currentExpanded = expandedFaq;
                    setExpandedFaq(
                      currentExpanded === "premium-benefits"
                        ? null
                        : "premium-benefits"
                    );
                  }}
                >
                  <span className="text-lg font-semibold text-gray-50">
                    Quels sont les avantages de la version premium ?
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={`w-6 h-6 transform transition-transform duration-200 ${
                      expandedFaq === "premium-benefits" ? "rotate-180" : ""
                    }`}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="#8FC23B"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>
                {expandedFaq === "premium-benefits" && (
                  <div className="px-4 py-3 bg-neutral-800">
                    <p className="text-gray-50 mb-4">
                      La <strong>version premium</strong> permet de supprimer
                      les annonces, d'accéder à une fonctionnalité
                      supplémentaire : la <em>sauvegarde automatique</em> via
                      GoogleDrive et supprimer les limitations (nombre de
                      recettes importées, de listes de courses créées, etc...).
                    </p>
                    <h3 className="text-xl font-semibold text-gray-50 mb-3">
                      Modalités
                    </h3>
                    <ul className="list-disc list-inside space-y-2 text-gray-50">
                      <li>
                        Consulter les{" "}
                        <Link
                          to="/conditions-generales"
                          className="text-[#8FC23B] hover:underline"
                          onClick={scrollToTop}
                        >
                          conditions générales de vente
                        </Link>
                      </li>
                      <li>L'achat sera effectué via le Google Play Store</li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>

        {/* Image Modal */}
        {selectedImage && (
          <div
            className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-4"
            onClick={() => setSelectedImage(null)}
          >
            <div className="relative w-full h-full flex items-center justify-center">
              <img
                src={selectedImage}
                alt="Zoom view"
                className="max-w-[90%] max-h-[90vh] w-auto h-auto object-contain"
                onClick={(e) => e.stopPropagation()}
              />
              <button
                className="absolute top-4 right-4 text-white text-2xl bg-black bg-opacity-50 w-10 h-10 rounded-full flex items-center justify-center hover:bg-opacity-75"
                onClick={() => setSelectedImage(null)}
              >
                ×
              </button>
            </div>
          </div>
        )}
      </>
    );
}

export default ClosedBeta; 