import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FaBook, FaChevronDown, FaRegCalendarAlt } from "react-icons/fa";
import { FaList } from "react-icons/fa6";
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2";
import { PiForkKnifeFill } from "react-icons/pi";
import { IoCartOutline } from "react-icons/io5";
import { MdImportExport, MdFolder } from "react-icons/md";
import { RiMenuFold3Line } from "react-icons/ri";
import { BiMenuAltRight } from "react-icons/bi";
import { LuRefreshCcw } from "react-icons/lu";

function Guide() {
  const [expandedSection, setExpandedSection] = useState<string | null>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Guide d'utilisation - Season App</title>
        <meta name="description" content="Découvrez comment utiliser Season pour planifier vos repas, gérer vos courses et cuisiner des plats de saison. Guide complet pour tirer le meilleur parti de l'application." />
        <meta name="keywords" content="guide season app, tutoriel season, planification repas, courses alimentaires, recettes de saison" />
      </Helmet>
      
      <div className="max-w-5xl mx-auto px-3 md:px-8 md:py-12 py-6 space-y-8">
        <h1 className="md:text-3xl text-2xl font-bold text-white">Guide d'utilisation</h1>

        {/* Recettes Section */}
        <section className="space-y-6">
          <div className="flex items-center gap-2 text-2xl font-semibold text-white">
            <PiForkKnifeFill className="text-[#7CA834]" />
            <h2>Recettes</h2>
          </div>
          
          {/* Créer une collection */}
          <div className="border border-gray-700 rounded-lg overflow-hidden">
            <button 
              className="w-full px-4 py-3 text-left bg-neutral-800 hover:bg-neutral-700 transition-colors duration-200 flex justify-between items-center"
              onClick={() => setExpandedSection(expandedSection === 'create-collection' ? null : 'create-collection')}
            >
              <span className="text-lg font-semibold text-gray-50">Créer une collection</span>
              <FaChevronDown className={`text-[#7CA834] transform transition-transform duration-200 ${expandedSection === 'create-collection' ? 'rotate-180' : ''}`} />
            </button>
            {expandedSection === 'create-collection' && (
              <div className="px-4 py-3 bg-neutral-800 text-gray-50">
                <ul className="list-disc list-inside space-y-2">
                  <li>Appuyer sur l'icône de livre <span className="inline-flex items-center text-[#7CA834]"><FaBook /></span> en bas de l'écran</li>
                  <li>Cliquer sur "+ Nouvelle collection"</li>
                  <li>Ajouter un titre et une description (optionnelle)</li>
                  <li>Appuyer sur "Créer" pour valider la création de la collection</li>
                </ul>
              </div>
            )}
          </div>

          {/* Ajouter une recette à une collection */}
          <div className="border border-gray-700 rounded-lg overflow-hidden">
            <button 
              className="w-full px-4 py-3 text-left bg-neutral-800 hover:bg-neutral-700 transition-colors duration-200 flex justify-between items-center"
              onClick={() => setExpandedSection(expandedSection === 'add-to-collection' ? null : 'add-to-collection')}
            >
              <span className="text-lg font-semibold text-gray-50">Ajouter une recette à une collection</span>
              <FaChevronDown className={`text-[#7CA834] transform transition-transform duration-200 ${expandedSection === 'add-to-collection' ? 'rotate-180' : ''}`} />
            </button>
            {expandedSection === 'add-to-collection' && (
              <div className="px-4 py-3 bg-neutral-800 text-gray-50">
                <ul className="list-disc list-inside space-y-2">
                  <li>Ouvrir la recette à ajouter</li>
                  <li>Appuyer sur l'icône de livre <span className="inline-flex items-center text-[#7CA834]"><FaBook /></span></li>
                  <li>Sélectionner la collection désirée pour y ajouter la recette</li>
                </ul>
              </div>
            )}
          </div>

          {/* Créer une recette */}
          <div className="border border-gray-700 rounded-lg overflow-hidden">
            <button 
              className="w-full px-4 py-3 text-left bg-neutral-800 hover:bg-neutral-700 transition-colors duration-200 flex justify-between items-center"
              onClick={() => setExpandedSection(expandedSection === 'create-recipe' ? null : 'create-recipe')}
            >
              <span className="text-lg font-semibold text-gray-50">Créer une recette</span>
              <FaChevronDown className={`text-[#7CA834] transform transition-transform duration-200 ${expandedSection === 'create-recipe' ? 'rotate-180' : ''}`} />
            </button>
            {expandedSection === 'create-recipe' && (
              <div className="px-4 py-3 bg-neutral-800 text-gray-50">
                <ul className="list-disc list-inside space-y-2">
                  <li>Appuyer sur l'icône + en bas de page</li>
                  <li>Cliquer sur "Créer une recette"</li>
                  <li>Remplir le formulaire (les champs "titre" et "nombre de portions" sont obligatoires)</li>
                  <li>Appuyer sur "Enregistrer" pour valider la création</li>
                </ul>
              </div>
            )}
          </div>

          {/* Rechercher une recette (web) */}
          <div className="border border-gray-700 rounded-lg overflow-hidden">
            <button 
              className="w-full px-4 py-3 text-left bg-neutral-800 hover:bg-neutral-700 transition-colors duration-200 flex justify-between items-center"
              onClick={() => setExpandedSection(expandedSection === 'import-recipe' ? null : 'import-recipe')}
            >
              <span className="text-lg font-semibold text-gray-50">Rechercher une recette (web)</span>
              <FaChevronDown className={`text-[#7CA834] transform transition-transform duration-200 ${expandedSection === 'import-recipe' ? 'rotate-180' : ''}`} />
            </button>
            {expandedSection === 'import-recipe' && (
              <div className="px-4 py-3 bg-neutral-800 text-gray-50">
                <p className="mb-4">
                  Cette fonctionnalité permet d'importer des recettes directement depuis des sites web de cuisine. 
                  Pour le moment, seuls les sites utilisant le format schema.org sont compatibles. Schema.org est une norme 
                  qui permet aux sites web de structurer leurs données de manière standardisée, facilitant ainsi leur lecture 
                  automatique. Cette fonctionnalité est en développement actif et sera étendue à davantage de sites à l'avenir.
                </p>
                <ul className="list-disc list-inside space-y-2">
                  <li>Cliquer sur l'icône + en bas de page</li>
                  <li>Sélectionner "Recherche internet"</li>
                  <li>Rechercher et ouvrir la recette souhaitée</li>
                  <li>Utiliser le bouton "Importer" en bas de la page de la recette</li>
                  <li>Vérifier les informations et appuyer sur "Enregistrer" pour confirmer</li>
                </ul>
              </div>
            )}
          </div>

          {/* Filtrer/Rechercher les recettes */}
          <div className="border border-gray-700 rounded-lg overflow-hidden">
            <button 
              className="w-full px-4 py-3 text-left bg-neutral-800 hover:bg-neutral-700 transition-colors duration-200 flex justify-between items-center"
              onClick={() => setExpandedSection(expandedSection === 'search-recipe' ? null : 'search-recipe')}
            >
              <span className="text-lg font-semibold text-gray-50">Filtrer/Rechercher les recettes</span>
              <FaChevronDown className={`text-[#7CA834] transform transition-transform duration-200 ${expandedSection === 'search-recipe' ? 'rotate-180' : ''}`} />
            </button>
            {expandedSection === 'search-recipe' && (
              <div className="px-4 py-3 bg-neutral-800 text-gray-50">
                <p className="mb-4">
                  Vous pouvez rechercher vos recettes en utilisant n'importe quel mot-clé présent dans la recette :
                  nom d'un ingrédient, titre de la recette, tag, etc...
                </p>
                <ul className="list-disc list-inside space-y-2">
                  <li>Pour filtrer les recettes :
                    <ul className="ml-6 mt-2 space-y-2">
                      <li>Cliquer sur l'icône <span className="inline-flex items-center text-[#7CA834]"><HiOutlineAdjustmentsHorizontal /></span> en haut à droite</li>
                      <li>Sélectionner les options de filtres désirées</li>
                      <li>Pour annuler les filtres, cliquer sur "Réinitialiser" dans la section "Filtres actifs" ou en bas de la page des filtres</li>
                    </ul>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </section>

        {/* Liste de courses Section */}
        <section className="space-y-6">
          <div className="flex items-center gap-2 text-2xl font-semibold text-white">
            <IoCartOutline className="text-[#7CA834]" />
            <h2>Liste de courses</h2>
          </div>

          {/* Ajouter un ingrédient */}
          <div className="border border-gray-700 rounded-lg overflow-hidden">
            <button 
              className="w-full px-4 py-3 text-left bg-neutral-800 hover:bg-neutral-700 transition-colors duration-200 flex justify-between items-center"
              onClick={() => setExpandedSection(expandedSection === 'add-ingredient' ? null : 'add-ingredient')}
            >
              <span className="text-lg font-semibold text-gray-50">Ajouter un ingrédient</span>
              <FaChevronDown className={`text-[#7CA834] transform transition-transform duration-200 ${expandedSection === 'add-ingredient' ? 'rotate-180' : ''}`} />
            </button>
            {expandedSection === 'add-ingredient' && (
              <div className="px-4 py-3 bg-neutral-800 text-gray-50">
                <h3 className="font-semibold mb-2">À partir d'une recette</h3>
                <ul className="list-disc list-inside space-y-2 mb-4">
                  <li>Ouvrir une recette</li>
                  <li>Cliquer sur "Ajouter au panier"</li>
                  <li>Sélectionner les ingrédients et la portion désirés</li>
                  <li>Valider</li>
                </ul>
                
                <h3 className="font-semibold mb-2">À partir de la recherche</h3>
                <ul className="list-disc list-inside space-y-2">
                  <li>Entrer le nom du produit dans le champ de recherche</li>
                  <li>Ajouter le produit dans les recommandations en appuyant sur l'icône + à droite</li>
                </ul>
              </div>
            )}
          </div>

          {/* Éditer un ingrédient */}
          <div className="border border-gray-700 rounded-lg overflow-hidden">
            <button 
              className="w-full px-4 py-3 text-left bg-neutral-800 hover:bg-neutral-700 transition-colors duration-200 flex justify-between items-center"
              onClick={() => setExpandedSection(expandedSection === 'edit-ingredient' ? null : 'edit-ingredient')}
            >
              <span className="text-lg font-semibold text-gray-50">Éditer un ingrédient</span>
              <FaChevronDown className={`text-[#7CA834] transform transition-transform duration-200 ${expandedSection === 'edit-ingredient' ? 'rotate-180' : ''}`} />
            </button>
            {expandedSection === 'edit-ingredient' && (
              <div className="px-4 py-3 bg-neutral-800 text-gray-50">
                <ul className="list-disc list-inside space-y-2">
                  <li>Dans la liste de courses, rester appuyé sur un produit pendant quelques secondes pour faire apparaître la fenêtre d'édition</li>
                </ul>
              </div>
            )}
          </div>

          {/* Changer l'ordre des catégories */}
          <div className="border border-gray-700 rounded-lg overflow-hidden">
            <button 
              className="w-full px-4 py-3 text-left bg-neutral-800 hover:bg-neutral-700 transition-colors duration-200 flex justify-between items-center"
              onClick={() => setExpandedSection(expandedSection === 'category-order' ? null : 'category-order')}
            >
              <span className="text-lg font-semibold text-gray-50">Changer l'ordre des catégories</span>
              <FaChevronDown className={`text-[#7CA834] transform transition-transform duration-200 ${expandedSection === 'category-order' ? 'rotate-180' : ''}`} />
            </button>
            {expandedSection === 'category-order' && (
              <div className="px-4 py-3 bg-neutral-800 text-gray-50">
                <ul className="list-disc list-inside space-y-2">
                  <li>Ouvrir le menu <span className="inline-flex items-center text-[#7CA834]"><RiMenuFold3Line /></span></li>
                  <li>Dans la section "Ordre des sections", appuyer longuement sur une catégorie</li>
                  <li>Tout en restant appuyé, déplacer celle-ci verticalement pour changer son emplacement</li>
                </ul>
              </div>
            )}
          </div>
        </section>

        {/* Planificateur Section */}
        <section className="space-y-6">
          <div className="flex items-center gap-2 text-2xl font-semibold text-white">
            <FaRegCalendarAlt className="text-[#7CA834]" />
            <h2>Planificateur</h2>
          </div>

          <div className="border border-gray-700 rounded-lg overflow-hidden">
            <button 
              className="w-full px-4 py-3 text-left bg-neutral-800 hover:bg-neutral-700 transition-colors duration-200 flex justify-between items-center"
              onClick={() => setExpandedSection(expandedSection === 'planner' ? null : 'planner')}
            >
              <span className="text-lg font-semibold text-gray-50">Ajouter une recette</span>
              <FaChevronDown className={`text-[#7CA834] transform transition-transform duration-200 ${expandedSection === 'planner' ? 'rotate-180' : ''}`} />
            </button>
            {expandedSection === 'planner' && (
              <div className="px-4 py-3 bg-neutral-800 text-gray-50">
                <ul className="list-disc list-inside space-y-2">
                  <li>Cliquer sur l'icône + du jour souhaité</li>
                  <li>Sélectionner la recette à ajouter</li>
                  <li>Il est possible de rechercher une recette en ouvrant le menu <span className="inline-flex items-center text-[#7CA834]"><BiMenuAltRight /></span> situé en haut à droite</li>
                </ul>
              </div>
            )}
          </div>
        </section>

        {/* Produits personnalisés Section */}
        <section className="space-y-6">
          <div className="flex items-center gap-2 text-2xl font-semibold text-white">
            <FaList className="text-[#7CA834]" />
            <h2>Produits personnalisés</h2>
          </div>

          <div className="border border-gray-700 rounded-lg overflow-hidden">
            <button 
              className="w-full px-4 py-3 text-left bg-neutral-800 hover:bg-neutral-700 transition-colors duration-200 flex justify-between items-center"
              onClick={() => setExpandedSection(expandedSection === 'custom-products' ? null : 'custom-products')}
            >
              <span className="text-lg font-semibold text-gray-50">Créer un produit personnalisé</span>
              <FaChevronDown className={`text-[#7CA834] transform transition-transform duration-200 ${expandedSection === 'custom-products' ? 'rotate-180' : ''}`} />
            </button>
            {expandedSection === 'custom-products' && (
              <div className="px-4 py-3 bg-neutral-800 text-gray-50">
                <ul className="list-disc list-inside space-y-2">
                  <li>Cliquer sur "Créer un produit personnalisé"</li>
                  <li>Choisir un titre</li>
                  <li>Sélectionner une catégorie (optionnel)</li>
                  <li>Ajouter une image avec le bouton "Image perso." (optionnel)</li>
                  <li>Cliquer sur "Créer" pour valider</li>
                </ul>
              </div>
            )}
          </div>
        </section>

        {/* Importer/Exporter des données Section */}
        <section className="space-y-6">
          <div className="flex items-center gap-2 text-2xl font-semibold text-white">
            <MdImportExport className="text-[#7CA834]" />
            <h2>Importer/Exporter des données</h2>
          </div>

          {/* Version gratuite */}
          <div className="border border-gray-700 rounded-lg overflow-hidden">
            <button 
              className="w-full px-4 py-3 text-left bg-neutral-800 hover:bg-neutral-700 transition-colors duration-200 flex justify-between items-center"
              onClick={() => setExpandedSection(expandedSection === 'free-version' ? null : 'free-version')}
            >
              <span className="text-lg font-semibold text-gray-50">Version gratuite</span>
              <FaChevronDown className={`text-[#7CA834] transform transition-transform duration-200 ${expandedSection === 'free-version' ? 'rotate-180' : ''}`} />
            </button>
            {expandedSection === 'free-version' && (
              <div className="px-4 py-3 bg-neutral-800 text-gray-50">
                <h3 className="font-semibold mb-2">Exporter les données</h3>
                <ul className="list-disc list-inside space-y-2 mb-4">
                  <li>Cliquer sur le bouton "Exporter les données"</li>
                  <li>Choisir parmi les options proposées par votre matériel :
                    <ul className="ml-6 mt-2 space-y-2">
                      <li>Google Drive (Recommandé)</li>
                      <li>E-Mail</li>
                      <li>OneDrive etc...</li>
                    </ul>
                  </li>
                  <li>Suivre les instructions selon l'option choisie</li>
                </ul>

                <h3 className="font-semibold mb-2">Importer les données</h3>
                <ul className="list-disc list-inside space-y-2">
                  <li>Choisir l'emplacement dans lequel vous avez placé au préalable la sauvegarde</li>
                  <li>Le fichier doit se terminer par l'extension .seas</li>
                </ul>
              </div>
            )}
          </div>

          {/* Version Premium */}
          <div className="border border-gray-700 rounded-lg overflow-hidden">
            <button 
              className="w-full px-4 py-3 text-left bg-neutral-800 hover:bg-neutral-700 transition-colors duration-200 flex justify-between items-center"
              onClick={() => setExpandedSection(expandedSection === 'premium-version' ? null : 'premium-version')}
            >
              <span className="text-lg font-semibold text-gray-50">Version Premium</span>
              <FaChevronDown className={`text-[#7CA834] transform transition-transform duration-200 ${expandedSection === 'premium-version' ? 'rotate-180' : ''}`} />
            </button>
            {expandedSection === 'premium-version' && (
              <div className="px-4 py-3 bg-neutral-800 text-gray-50">
                <ul className="list-disc list-inside space-y-2 mb-4">
                  <li>Se connecter au service Google Drive</li>
                  <li>Par défaut, l'application crée un dossier "Season - Sauvegardes" à la racine de votre service cloud</li>
                  <li>Si vous utilisez cette fonctionnalité pour la première fois :
                    <ul className="ml-6 mt-2 space-y-2">
                      <li>Activer la sauvegarde automatique en cliquant sur <span className="inline-flex items-center text-[#7CA834]"><LuRefreshCcw /></span></li>
                    </ul>
                  </li>
                  <li>Si vous souhaitez récupérer ou transférer des données déjà existantes :
                    <ul className="ml-6 mt-2 space-y-2">
                      <li>Cliquer sur <span className="inline-flex items-center text-[#7CA834]"><MdFolder /></span> et charger les données souhaitées</li>
                      <li>Vous pourrez ensuite activer la sauvegarde automatique <span className="inline-flex items-center text-[#7CA834]"><LuRefreshCcw /></span></li>
                    </ul>
                  </li>
                </ul>

                <h3 className="font-semibold mb-2">Sauvegarde rapide</h3>
                <p>
                  La sauvegarde rapide permet de conserver les données actuelles de manière persistante sans passer par l'interface Google Drive.
                  Elle ne sera jamais "effacée" ou "écrasée" contrairement à la sauvegarde automatique qui sera rafraîchie après chaque changement dans vos données.
                </p>
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
}

export default Guide;