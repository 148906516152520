import styled from 'styled-components';
import { Link } from 'react-router-dom';

const FooterContainer = styled.footer`
  width: 100%;
  background-color: ${props => props.theme.background};
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

const FooterLink = styled(Link)`
  color: ${({ theme }) => theme.text};
  text-decoration: none;
  margin: 0 1rem;
  font-size: 0.9rem;
  
  @media (max-width: 768px) {
    font-size: 12px;
    margin: 0;
  }

  &:hover {
    text-decoration: underline;
  }
`;

function Footer() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <FooterContainer>
      <FooterLink to="/mentions-legales" onClick={scrollToTop}>
        Mentions légales
      </FooterLink>
      <FooterLink to="/conditions-generales" onClick={scrollToTop}>
        {window.innerWidth <= 768 ? 'CGU/CGV' : 'Conditions générales d\'utilisation et de vente'}
      </FooterLink>
      <FooterLink to="/politique-confidentialite" onClick={scrollToTop}>
        Confidentialité
      </FooterLink>
    </FooterContainer>
  );
}

export default Footer; 