function PrivacyPolicy() {
  return (
    <div className="max-w-6xl mx-auto px-4 py-2">
      <h1 className="text-3xl font-bold mb-8">Politique de Confidentialité</h1>

      <section className="space-y-6">
        <div>
          <h2 className="text-2xl font-semibold mb-4">Notre Engagement</h2>
          <p className="mb-4 text-justify">
            Chez Season: Listes & Recettes, nous accordons une importance capitale à la protection de votre vie privée 
            et de vos données personnelles. Cette politique de confidentialité détaille de manière transparente 
            nos pratiques concernant la collecte, l'utilisation, et la protection de vos informations 
            lorsque vous utilisez notre application. Nous nous engageons à respecter pleinement le 
            Règlement Général sur la Protection des Données (RGPD) et toutes les lois applicables en 
            matière de protection des données.
          </p>
        </div>

        <div>
          <h2 className="text-2xl font-semibold mb-4">Données Collectées</h2>
          <p className="mb-4 text-justify">Dans le cadre de nos services, nous collectons uniquement les données suivantes :</p>
          <ul className="list-disc ml-6 mb-4">
            <li>
              <strong>Données de transaction :</strong> Uniquement l'ID de transaction et la localisation 
              de transaction à des fins de suivi client
            </li>
          </ul>
          <p className="mb-4 text-justify">
            Il est important de noter qu'aucune autre donnée n'est stockée sur nos serveurs. Toutes les données 
            sont gérées localement sur votre appareil ou via les services Google que vous utilisez.
          </p>
        </div>

        <div>
          <h2 className="text-2xl font-semibold mb-4">Utilisation de la Caméra</h2>
          <p className="mb-4 text-justify">
            Notre application demande l'accès à la caméra de votre appareil uniquement dans le but de vous permettre d'ajouter des images à l'application. Voici les points importants concernant l'utilisation de la caméra :
          </p>
          <ul className="list-disc ml-6 mb-4">
            <li>
              <strong>Usage limité :</strong> La caméra est utilisée exclusivement pour la prise de photos que vous souhaitez ajouter à l'application
            </li>
            <li>
              <strong>Aucun accès au contenu :</strong> L'application n'a jamais accès à vos photos ou au contenu de votre appareil sans votre action explicite
            </li>
            <li>
              <strong>Contrôle utilisateur :</strong> Vous pouvez à tout moment gérer les permissions de la caméra dans les paramètres de votre appareil
            </li>
            <li>
              <strong>Stockage local :</strong> Les photos prises via l'application sont stockées localement sur votre appareil et ne sont pas partagées avec nos serveurs
            </li>
          </ul>
        </div>

        <div>
          <h2 className="text-2xl font-semibold mb-4">Base Légale du Traitement</h2>
          <p className="mb-4 text-justify">Nous traitons vos données sur les bases légales suivantes :</p>
          <ul className="list-disc ml-6 mb-4">
            <li>
              <strong>Consentement :</strong> Pour la collecte de données non essentielles et 
              l'affichage de publicités personnalisées
            </li>
            <li>
              <strong>Exécution du contrat :</strong> Pour fournir les services essentiels de l'application
            </li>
            <li>
              <strong>Intérêt légitime :</strong> Pour améliorer nos services et assurer la sécurité
            </li>
            <li>
              <strong>Obligation légale :</strong> Pour respecter nos obligations réglementaires
            </li>
          </ul>
        </div>

        <div>
          <h2 className="text-2xl font-semibold mb-4">Utilisation Détaillée de Vos Données</h2>
          <p className="mb-4 text-justify">Vos données sont utilisées pour les finalités suivantes :</p>
          <ul className="list-disc ml-6 mb-4">
            <li>
              <strong>Amélioration du service :</strong> Analyse des performances, correction des bugs, 
              optimisation de l'interface utilisateur
            </li>
            <li>
              <strong>Personnalisation :</strong> Adaptation de l'expérience utilisateur selon vos préférences
            </li>
            <li>
              <strong>Publicité :</strong> Affichage de publicités pertinentes via AdMob (uniquement avec 
              votre consentement explicite)
            </li>
            <li>
              <strong>Support technique :</strong> Résolution des problèmes et assistance utilisateur
            </li>
          </ul>
        </div>

        <div>
          <h2 className="text-2xl font-semibold mb-4">Protection et Sécurité des Données</h2>
          <p className="mb-4 text-justify">
            Nous mettons en œuvre des mesures de sécurité techniques et organisationnelles robustes pour 
            protéger vos données :
          </p>
          <ul className="list-disc ml-6 mb-4">
            <li>Chiffrement des données en transit et au repos</li>
            <li>Contrôles d'accès stricts aux données</li>
            <li>Surveillance continue de la sécurité</li>
          </ul>
        </div>

        <div>
          <h2 className="text-2xl font-semibold mb-4">Durée de Conservation</h2>
          <p className="mb-4 text-justify">
            Nous conservons vos données personnelles uniquement pendant la durée nécessaire aux finalités 
            pour lesquelles elles ont été collectées :
          </p>
          <ul className="list-disc ml-6 mb-4">
            <li>Données de compte : Jusqu'à la suppression de votre compte</li>
            <li>Données d'utilisation : 12 mois maximum</li>
            <li>Données de support : 24 mois après la dernière interaction</li>
          </ul>
        </div>

        <div>
          <h2 className="text-2xl font-semibold mb-4">Transferts de Données</h2>
          <p className="mb-4 text-justify">
            Nôtre partenaire technique (AdMob) peut traiter vos données en dehors de l'UE. 
            Ces transferts sont encadrés par des garanties appropriées (clauses contractuelles types de 
            la Commission européenne) pour assurer un niveau de protection adéquat.
          </p>
        </div>

        <div>
          <h2 className="text-2xl font-semibold mb-4">Utilisation de Google Search</h2>
          <p className="mb-4 text-justify">
            Notre application intègre le service Google Search pour la fonctionnalité de recherche. 
            Voici ce que vous devez savoir concernant son utilisation :
          </p>
          <ul className="list-disc ml-6 mb-4">
            <li>
              <strong>Données transmises à Google :</strong> Lorsque vous effectuez une recherche, 
              les termes de recherche et certaines informations techniques (comme votre adresse IP et 
              les paramètres de votre navigateur) sont transmis à Google
            </li>
            <li>
              <strong>Finalité :</strong> Ces données sont utilisées pour fournir des résultats de 
              recherche pertinents et améliorer la qualité du service
            </li>
            <li>
              <strong>Politique de Google :</strong> En utilisant cette fonctionnalité, vos données 
              sont également soumises à la politique de confidentialité de Google 
              (disponible sur http://www.google.com/privacy.html)
            </li>
            <li>
              <strong>Contrôle utilisateur :</strong> Vous pouvez désactiver l'historique de recherche 
              dans vos paramètres Google et gérer vos préférences de confidentialité via votre compte Google
            </li>
          </ul>
          <p className="mb-4 text-justify">
            Nous n'avons pas accès direct aux données collectées par Google lors de vos recherches. 
            Pour plus d'informations sur la manière dont Google traite vos données, nous vous invitons 
            à consulter leur politique de confidentialité.
          </p>
        </div>

        <div>
          <h2 className="text-2xl font-semibold mb-4">Intégration avec les Services Google</h2>
          <p className="mb-4 text-justify">
            En plus de Google Search, notre application peut utiliser d'autres services Google pour 
            améliorer votre expérience :
          </p>
          <ul className="list-disc ml-6 mb-4">
            <li>
              <strong>Google Analytics :</strong> Pour analyser l'utilisation de l'application et 
              améliorer nos services
            </li>
            <li>
              <strong>Google Cloud Platform :</strong> Pour l'hébergement et le traitement sécurisé 
              des données
            </li>
          </ul>
          <p className="mb-4 text-justify">
            Ces intégrations sont configurées pour respecter les normes de confidentialité les plus 
            strictes et nous limitons au maximum les données partagées avec ces services.
          </p>
        </div>

        <div>
          <h2 className="text-2xl font-semibold mb-4">Vos Droits Détaillés</h2>
          <p className="mb-4 text-justify">Conformément au RGPD, vous disposez des droits suivants :</p>
          <ul className="list-disc ml-6 mb-4">
            <li>
              <strong>Droit d'accès :</strong> Obtenir une copie de vos données personnelles
            </li>
            <li>
              <strong>Droit de rectification :</strong> Corriger des données inexactes
            </li>
            <li>
              <strong>Droit à l'effacement :</strong> Demander la suppression de vos données
            </li>
            <li>
              <strong>Droit à la limitation :</strong> Restreindre le traitement de vos données
            </li>
            <li>
              <strong>Droit à la portabilité :</strong> Recevoir vos données dans un format structuré
            </li>
            <li>
              <strong>Droit d'opposition :</strong> S'opposer au traitement de vos données
            </li>
          </ul>
          <p className="mb-4 text-justify">
            Pour exercer ces droits ou pour toute question, contactez-nous à contact@season-app.com. 
            Nous nous engageons à répondre dans un délai maximum d'un mois.
          </p>
        </div>

        <div>
        <h2 className="text-2xl font-semibold mb-4">Intégration avec Google Drive</h2>
          <p className="mb-4 text-justify">
            Notre application utilise Google Drive pour la sauvegarde et la synchronisation de vos données :
          </p>
          
          <h3 className="text-xl font-semibold mb-2">Données stockées sur Google Drive :</h3>
          <ul className="list-disc ml-6 mb-4">
            <li>Sauvegardes de vos listes et recettes</li>
            <li>Vos collections</li>
            <li>Vos produits personnalisés</li>
          </ul>
          <p className="mb-4 text-justify">
            <strong>Important :</strong> Aucune donnée sensible n'est stockée sur Google Drive. 
            Les données sauvegardées sont strictement limitées aux fonctionnalités de l'application 
            (listes, recettes, préférences) et ne contiennent aucune information personnelle sensible.
          </p>

          <h3 className="text-xl font-semibold mb-2">Fonctionnement :</h3>
          <ul className="list-disc ml-6 mb-4">
            <li>Un dossier dédié "Season - Sauvegardes" est créé dans votre Google Drive</li>
            <li>Les sauvegardes peuvent être automatiques (toutes les 5 minutes) ou manuelles</li>
            <li>Seuls les fichiers créés par notre application sont accessibles</li>
            <li>Nous utilisons uniquement les permissions minimales nécessaires (drive.file et drive.appdata)</li>
          </ul>

          <h3 className="text-xl font-semibold mb-2">Contrôle de vos données :</h3>
          <ul className="list-disc ml-6 mb-4">
            <li>Vous pouvez activer/désactiver la synchronisation à tout moment</li>
            <li>Vous pouvez gérer et supprimer vos sauvegardes depuis l'application</li>
            <li>La déconnexion de Google Drive supprime l'accès de l'application à vos données</li>
            <li>Vous gardez le contrôle total sur vos fichiers dans Google Drive</li>
          </ul>

          <h3 className="text-xl font-semibold mb-2">Utilisation de l'espace de stockage :</h3>
          <ul className="list-disc ml-6 mb-4">
            <li>Les sauvegardes utilisent votre espace Google Drive personnel</li>
            <li>Vous pouvez consulter l'espace utilisé dans l'application</li>
            <li>Nous vous informons si vous approchez des limites de stockage</li>
          </ul>

          <h3 className="text-xl font-semibold mb-2">Sécurité :</h3>
          <ul className="list-disc ml-6 mb-4">
            <li>Toutes les données sont chiffrées pendant le transfert</li>
            <li>L'authentification se fait via OAuth 2.0 de Google</li>
            <li>Aucune donnée de connexion Google n'est stockée par notre application</li>
          </ul>
          <h2 className="text-2xl font-semibold mb-4">Gestion de Vos Données et Suppression de Compte</h2>
          <p className="mb-4 text-justify">
            Season utilise exclusivement Google pour l'authentification et le stockage des données. 
            Nous ne stockons aucune donnée utilisateur sur nos propres serveurs.
          </p>
          <p className="mb-4 text-justify">
            Pour gérer ou supprimer vos données, nous avons créé un{" "}
            <a href="/account-deletion" className="text-blue-600 hover:underline">
              guide détaillé de suppression de compte
            </a>
            {" "}qui vous explique pas à pas comment :
          </p>
          <ul className="list-disc ml-6 mb-4">
            <li>Supprimer vos données dans l'application</li>
            <li>Vous déconnecter de l'application</li>
            <li>Révoquer l'accès à Google Drive</li>
            <li>Supprimer vos données de Google Drive</li>
          </ul>

        </div>

        <div>
          <h2 className="text-2xl font-semibold mb-4">Modifications de la Politique</h2>
          <p className="mb-4 text-justify">
            Nous nous réservons le droit de modifier cette politique de confidentialité. Les modifications 
            entrent en vigueur dès leur publication. Pour les changements significatifs, nous vous 
            informerons directement via l'application ou par email.
          </p>
          <p className="mb-4 text-justify">
            Dernière mise à jour : 15/01/2025
          </p>
        </div>

        <div>
          <h2 className="text-2xl font-semibold mb-4">Contact</h2>
          <p className="mb-4 text-justify">
            Pour toute question concernant cette politique ou vos données personnelles, contactez notre 
            Délégué à la Protection des Données :
          </p>
          <ul className="list-disc ml-6 mb-4">
            <li>Email : contact@season-app.com</li>
            <li>Adresse : 42 rue Pasteur, 35300 Fougères, FRANCE</li>
            <li>Téléphone : (+33) 06 95 17 35 29</li>
          </ul>
        </div>
      </section>
    </div>
  );
}

export default PrivacyPolicy; 