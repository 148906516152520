function AccountDeletion() {
  return (
    <div className="max-w-6xl mx-auto px-4 py-2">
      <h1 className="text-3xl font-bold mb-8">Suppression de compte et données - Season App</h1>

      <section className="space-y-6">
        <div>
          <h2 className="text-2xl font-semibold mb-4">Comment gérer vos données dans Season</h2>
          <p className="mb-4 text-justify">
            Season utilise exclusivement Google pour l'authentification et le stockage des données. 
            Nous ne stockons aucune donnée utilisateur sur nos propres serveurs. Voici comment gérer vos données :
          </p>
        </div>

        <div>
          <h2 className="text-xl font-semibold mb-4">1. Supprimer vos données dans l'application</h2>
          <ol className="list-decimal ml-6 mb-4 space-y-2">
            <li>Ouvrez l'application Season</li>
            <li>Allez dans la section Google Drive</li>
            <li>Appuyez sur "Gérer"</li>
            <li>Sélectionnez et supprimez les sauvegardes que vous souhaitez effacer</li>
          </ol>
        </div>

        <div>
          <h2 className="text-xl font-semibold mb-4">2. Se déconnecter de l'application</h2>
          <ol className="list-decimal ml-6 mb-4 space-y-2">
            <li>Ouvrez l'application Season</li>
            <li>Allez dans la section Google Drive</li>
            <li>Appuyez sur "Déconnexion"</li>
          </ol>
        </div>

        <div>
          <h2 className="text-xl font-semibold mb-4">3. Révoquer l'accès à Google Drive</h2>
          <ol className="list-decimal ml-6 mb-4 space-y-2">
            <li>
              Visitez{" "}
              <a 
                href="https://myaccount.google.com/security-checkup" 
                className="text-blue-600 hover:underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                Google Security Checkup
              </a>
            </li>
            <li>Cliquez sur "Applications tierces avec accès au compte"</li>
            <li>Recherchez "Season"</li>
            <li>Cliquez sur "Révoquer l'accès"</li>
          </ol>
        </div>

        <div>
          <h2 className="text-xl font-semibold mb-4">4. Supprimer les données de Google Drive</h2>
          <ol className="list-decimal ml-6 mb-4 space-y-2">
            <li>
              Visitez{" "}
              <a 
                href="https://drive.google.com" 
                className="text-blue-600 hover:underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                Google Drive
              </a>
            </li>
            <li>Recherchez le dossier "Season - Sauvegardes"</li>
            <li>Faites un clic droit et sélectionnez "Supprimer"</li>
          </ol>
        </div>

        <div>
          <h2 className="text-xl font-semibold mb-4">Informations importantes</h2>
          <ul className="list-disc ml-6 mb-4 space-y-2">
            <li>Season utilise Google pour l'authentification et le stockage</li>
            <li>Nous ne stockons aucune donnée utilisateur sur nos serveurs</li>
            <li>Toutes vos données sont stockées dans votre Google Drive personnel</li>
            <li>Vous avez le contrôle total sur vos données via les outils Google</li>
          </ul>
        </div>

        <div>
          <h2 className="text-xl font-semibold mb-4">Contact</h2>
          <p className="mb-4">
            Si vous avez des questions concernant vos données, contactez-nous à :{" "}
            <a href="mailto:contact@season-app.com" className="text-blue-600 hover:underline">
              contact@season-app.com
            </a>
          </p>
        </div>
      </section>
    </div>
  );
}

export default AccountDeletion; 