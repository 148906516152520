import { ImageType } from '../types/ImageTypes';
import { FaSeedling } from 'react-icons/fa';

interface HomeDesktopProps {
  mainImage: ImageType;
  googlePlayImg: string;
}

export function HomeDesktop({ mainImage, googlePlayImg }: HomeDesktopProps) {
  return (
    <div className="hidden md:flex flex-col items-center w-full">
      <h1 className="text-4xl font-bold mt-8 mb-12 px-4 text-center pt-16">
        <span className="text-season-green">Season:</span> Listes & Recettes
      </h1>
      <div className="w-full max-w-7xl mx-auto flex flex-col items-center gap-8 md:gap-16 px-8">
        <div className="flex flex-row justify-between items-start gap-16 w-full">
          <div className="flex-1 relative w-full">
            <img
              src={mainImage.src}
              alt={mainImage.alt}
              className="w-full h-[45vh] object-cover rounded-2xl shadow-lg"
            />
          </div>

          <div className="flex flex-col flex-1 gap-8">
            <h2 className="text-2xl font-bold text-left">
              <FaSeedling className="inline-block mr-2 text-season-green" />
              Au gré des saisons
            </h2>
            <div className="text-lg text-gray-600 dark:text-gray-300 leading-relaxed flex flex-col gap-4 text-justify">
              <p>
                <strong className=" text-season-green">Season:</strong> L&R est
                une application intuitive pour gérer{" "}
                <strong>vos repas au quotidien</strong>. Créez ou <strong>importez
                facilement des recettes</strong> de saison depuis le web et ajoutez <strong>les ingrédients en un clic</strong> dans vos listes de courses.
              </p>
              <p>
                Grâce à <strong>un planificateur pratique</strong>, organisez vos repas pour
                toute la semaine en quelques étapes. <strong>Exportez ou importez vos
                données</strong> pour une gestion simple et sécurisée, idéale pour
                changer d'appareil ou créer des sauvegardes.
              </p>
            </div>
          </div>
        </div>

        <div>
          <a
            href="https://play.google.com/store"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={googlePlayImg}
              alt="Get it on Google Play"
              className="w-52 hover:opacity-90 transition-opacity"
            />
          </a>
        </div>
      </div>
    </div>
  );
} 